<template>
  <div class="actions">
    <a class='add-button' title="Добавить пользвателя" @click="create_account_dialog = true"><i class="fa-solid fa-circle-plus"></i></a>
  </div>
  <span v-if="error" class="error">{{error}}</span>
  <div id="content">
    <div id="loader" v-show="loading">
      <i class="fas fa-spinner fa-pulse"></i>
    </div>
    <table v-show="accounts.length">
      <thead>
        <th>ID</th>
        <th>Имя</th>
        <th>Телефон</th>
        <th>Имя пользователя</th>
        <th>Дата регистрации</th>
        <th>Роль</th>
        <th>Турниров</th>
        <th>Рейтингов</th>
        <th>Гандикапов</th>
        <th>Игроков</th>
        <th></th>
      </thead>
      <tbody>
        <tr v-for="account in accounts" :key="account.id">
          <td>{{account.id}}</td>
          <td>{{account.name}}</td>
          <td>
            <label>{{account.phone ? account.phone : "" }}</label>
            <a v-if="account.phone" :href="`https://api.whatsapp.com/send/?phone=${account.phone}&text&type=phone_number&app_absent=0`" target="_blank" class="whatsapp">
              <i class="fa-brands fa-whatsapp"></i>
            </a> 
          </td>
          <td>{{account.username}}</td>
          <td>{{formatDateTime(account.register_date)}}</td>
          <td>{{account.role}}</td>
          <td>{{account.tournaments}}</td>
          <td>{{account.ratings}}</td>
          <td>{{account.handicaps}}</td>
          <td>{{account.players}}</td>
          <td class="actions-cell">
            <a title="Редактировать" @click="account_to_edit = { ...account }; edit_account_dialog = true;"><i class="fa-solid fa-pencil"></i></a>
            <a title="Сменить пароль" @click="account_to_reset = account; reset_account_dialog = true"><i class="fa-solid fa-unlock"></i></a>
            <a title="Удалить" @click="account_to_delete = account; delete_account_dialog = true;"><i class="fa-solid fa-trash-can"></i></a>
          </td>
        </tr>
      </tbody>
    </table>
    <div class="modal" v-if="delete_account_dialog && account_to_delete">
      <div class="modal-content">
        <div class="modal-header">
          <a @click="delete_account_dialog=false"><i class="fa-solid fa-xmark"></i></a>
        </div>
        <label>{{ `Для удаления ${account_to_delete.username} напишите его имя пользователя` }}</label>
        <input :placeholder="account_to_delete.username" v-model="account_to_delete_username_confirm"/>
        <div class="modal-buttons">
          <button v-show="account_to_delete.username == account_to_delete_username_confirm" class="warning" @click="deleteAccount">
            <i class="fas fa-spinner fa-pulse" v-if="deleting_account"></i>
            <span v-else>Удалить</span>
          </button>
        </div>
      </div>
    </div>
    <div class="modal" v-if="reset_account_dialog  && account_to_reset">
      <div class="modal-content">
        <div class="modal-header">
          <a @click="reset_account_dialog=false"><i class="fa-solid fa-xmark"></i></a>
        </div>
        <label>{{ `Сброс пароля для ${account_to_reset.username}` }}</label>
        <input type="password" placeholder="Новый пароль" v-model="reset_account_new_password"/>
        <input type="password" placeholder="Повторите новый пароль" v-model="reset_account_new_password_confirm"/>
        <div class="modal-buttons">
          <button v-show="reset_account_new_password == reset_account_new_password_confirm" @click="resetPassword">
            <i class="fas fa-spinner fa-pulse" v-if="reseting_account"></i>
            <span v-else>Поменять пароль</span>
          </button>
        </div>
        <span v-if="resetPasswordError != ''" class="error">{{resetPasswordError}}</span>
      </div>
    </div>
    <div class="modal" v-if="edit_account_dialog  && account_to_edit">
      <div class="modal-content">
        <div class="modal-header">
          <a @click="edit_account_dialog=false"><i class="fa-solid fa-xmark"></i></a>
        </div>
        <label>{{ `Редактирование ${account_to_edit.username}` }}</label>
        <span>ФИО:</span>
        <input v-model="account_to_edit.name"/>
        <span>Телефон:</span>
        <input v-model="account_to_edit.phone"/>
        <span>Роль:</span>
        <select v-model="account_to_edit.role">
          <option value="operator">Operator</option>
          <option value="administrator">Administrator</option>
        </select>
        <div class="modal-buttons">
          <button @click="editAccount">
            <i class="fas fa-spinner fa-pulse" v-if="editing_account"></i>
            <span v-else>Сохранить</span>
          </button>
        </div>
      </div>
    </div>
    <div class="modal" v-if="create_account_dialog">
      <div class="modal-content">
        <div class="modal-header">
          <a @click="create_account_dialog=false"><i class="fa-solid fa-xmark"></i></a>
        </div>
        <label>{{ `Создание нового пользователя` }}</label>
        <input type="text" placeholder="ФИО" v-model="create_account_name" v-on:keyup.enter="createAccount">
        <input type="text" placeholder="Username" v-model="create_account_username" v-on:keyup.enter="createAccount">
        <input type="password" placeholder="Password" v-model="create_account_password" v-on:keyup.enter="createAccount">
        <input type="text" placeholder="Телефон" v-model="create_account_phone" v-on:keyup.enter="createAccount">
        <select v-model="create_account_role">
          <option value="operator">Operator</option>
          <option value="administrator">Administrator</option>
        </select>
        <div class="modal-buttons">
          <button @click="createAccount">
            <i class="fas fa-spinner fa-pulse" v-if="creating_account"></i>
            <span v-else>Создать</span>
          </button>
        </div>
        <span v-if="createAccountError != ''" class="error">{{createAccountError}}</span>
      </div>
    </div>
  </div>
</template>

<script>
import axios from 'axios'
export default {
  name: 'AdminAccountsPage',
  data: function() {
    return {
      username: '',
      role: '',
      error: '',
      loading: false,
      accounts: [],
      createAccountError: '',
      resetPasswordError: '',
      account_to_delete: null,
      deleting_account: false,
      delete_account_dialog: false,
      account_to_delete_username_confirm: null,
      account_to_reset: null,
      reseting_account: false,
      reset_account_dialog: false,
      reset_account_new_password: null,
      reset_account_new_password_confirm: null,
      account_to_edit: null,
      editing_account: false,
      edit_account_dialog: false,
      creating_account: false,
      create_account_dialog: false,
      create_account_username: null,
      create_account_name: null,
      create_account_password: null,
      create_account_role: null,
      create_account_phone: null
    }
  },
  methods: {
    getAccounts: function() {
      this.loading = true;
      this.error = '';
      this.accounts = [];
      axios
      .get('/api/account/list', {headers: {'x-access-token': localStorage.token}})
      .then(response => {
        this.accounts = response.data;
      })
      .catch(error => {
        console.log(error);
        if (error.response) {
          if (error.response.data == 'Invalid Token' || error.response.data == 'A token is required for authentication') {
            localStorage.removeItem('username');
            localStorage.removeItem('name');
            localStorage.removeItem('role');
            localStorage.removeItem('token');
            this.$router.push({ path: '/login' })
            return;
          }
          this.error = error.response.data;
        } else {
          this.error = 'Не получилось запросить пользователей'
        }
      })
      .finally(() => this.loading = false)
    },
    createAccount: function() {
      if (!(this.create_account_username && this.create_account_name && this.create_account_password && this.create_account_role && this.create_account_phone)) {
        this.createAccountError = 'Не все поля заполненны'
        return
      }
      this.creating_account = true;
      this.createAccountError = '';
      this.error = '';
      axios
      .post('/api/account/create', {username: this.create_account_username, password: this.create_account_password, name: this.create_account_name, phone: this.create_account_phone, role: this.create_account_role}, {headers: {'x-access-token': localStorage.token}})
      .then(() => this.getAccounts())
      .catch(error => {
        console.log(error);
        if (error.response) {
          if (error.response.data == 'Invalid Token' || error.response.data == 'A token is required for authentication') {
            localStorage.removeItem('username');
            localStorage.removeItem('name');
            localStorage.removeItem('role');
            localStorage.removeItem('token');
            this.$router.push({ path: '/login' })
            return;
          }
          this.createAccountError = error.response.data;
        } else {
          this.createAccountError = 'Не получилось создать пользователей'
        }
      })
      .finally(() => {
        this.creating_account = false;
        this.create_account_dialog = false;
        this.create_account_username = null;
        this.create_account_name = null;
        this.create_account_password = null;
        this.create_account_role = null;
        this.create_account_phone = null;
      })
    },
    deleteAccount: function() {
      if (this.account_to_delete.username != this.account_to_delete_username_confirm) {
        this.error = 'Неправильный username';
        return
      }
      this.deleting_account = true;
      this.error = '';
      axios
      .post('/api/account/delete', {username: this.account_to_delete.username}, {headers: {'x-access-token': localStorage.token}})
      .then(response => {
        if (response.data) {
          this.getAccounts();
        }
      })
      .catch(error => {
        console.log(error);
        if (error.response) {
          if (error.response.data == 'Invalid Token' || error.response.data == 'A token is required for authentication') {
            localStorage.removeItem('username');
            localStorage.removeItem('name');
            localStorage.removeItem('role');
            localStorage.removeItem('token');
            this.$router.push({ path: '/login' })
            return;
          }
          this.error = error.response.data;
        } else {
          this.error = 'Не получилось удалить пользователей'
        }
      })
      .finally(() => {
        this.deleting_account = false;
        this.account_to_delete_username_confirm = null;
        this.account_to_delete = null;
        this.delete_account_dialog = false;
      })
    },
    resetPassword: function() {
      this.resetPasswordError = '';
      this.error = ''
      if (this.reset_account_new_password == '' || this.reset_account_new_password != this.reset_account_new_password_confirm) {
        this.reset_account_new_password_confirm = null;
        this.resetPasswordError = 'Пароли не совпадают';
        return;
      }
      this.reseting_account = true;
      axios
      .post('/api/account/reset', {username: this.account_to_reset.username, new_password: this.reset_account_new_password}, {headers: {'x-access-token': localStorage.token}})
      .then(() => {})
      .catch(error => {
        console.log(error);
        if (error.response) {
          if (error.response.data == 'Invalid Token' || error.response.data == 'A token is required for authentication') {
            localStorage.removeItem('username');
            localStorage.removeItem('name');
            localStorage.removeItem('role');
            localStorage.removeItem('token');
            this.$router.push({ path: '/login' })
            return;
          }
          this.error = error.response.data;
        } else {
          this.error = 'Не получилось сменить пароль пользователя'
        }
      })
      .finally(() => {
        this.reseting_account = false;
        this.reset_account_dialog = false;
        this.reset_account_new_password = null;
        this.reset_account_new_password_confirm = null;
        this.account_to_reset = null;
      })
    },
    editAccount: function() {
      this.error = ''
      this.editing_account = true;
      axios
      .post('/api/account/edit', {username: this.account_to_edit.username, name: this.account_to_edit.name, phone: this.account_to_edit.phone, role: this.account_to_edit.role}, {headers: {'x-access-token': localStorage.token}})
      .then(() => {})
      .catch(error => {
        console.log(error);
        if (error.response) {
          if (error.response.data == 'Invalid Token' || error.response.data == 'A token is required for authentication') {
            localStorage.removeItem('username');
            localStorage.removeItem('name');
            localStorage.removeItem('role');
            localStorage.removeItem('token');
            this.$router.push({ path: '/login' })
            return;
          }
          this.error = error.response.data;
        } else {
          this.error = 'Не получилось отредактировать пользователя'
        }
      })
      .finally(() => {
        this.editing_account = false;
        this.edit_account_dialog = false;
        this.account_to_edit = null;
        this.getAccounts();
      })
    },
    formatDateTime(timestamp) {
      if (!timestamp) return ''
      let date = new Date(parseInt(timestamp,10));
      let year = date.getFullYear();
      let month = date.getMonth() + 1;
      if (month.toString().length == 1) month = '0' + month;
      let day = date.getDate();
      if (day.toString().length == 1) day = '0' + day;
      let hour = date.getHours();
      if (hour.toString().length == 1) hour = '0' + hour;
      let min = date.getMinutes();
      if (min.toString().length == 1) min = '0' + min;
      let result = `${day}.${month}.${year} ${hour}:${min}`;
      return result
    },
  },
  computed: {
    accountsById() {
      return this.groupArrayBy(this.accounts, 'id');
    }
  },
  mounted() {
    this.role = localStorage.role;
    this.username = localStorage.username;
    if (this.role != 'operator' && this.role != 'administrator') {
      this.$router.replace({ path: '/login' })
      return;
    }
    this.getAccounts();
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
#admin-content {
  overflow: auto;
}
.actions-cell {
  display: flex;
  justify-content: space-evenly;
}
.actions-cell a {
  margin-right: 5px;
}
.actions-cell a:hover {
  color: #248d24;
}
.whatsapp {
  margin-left: 5px;
  color: #028c32;
  text-decoration: none;
}
</style>
